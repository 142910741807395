import React from 'react';

const isBrowser = () => typeof window !== 'undefined';

const COOKIEBOT_DECLARATION_WRAPPER_ID = 'cookiebot-declaration-wrapper';

export default function CookieDeclaration() {
  React.useEffect(() => {
    if (isBrowser()) {
      const cookiebotCookieDeclaratioScript = document.createElement('script');
      cookiebotCookieDeclaratioScript.id = 'CookieDeclaration';
      cookiebotCookieDeclaratioScript.src =
        'https://consent.cookiebot.com/2e4b2aa6-8f31-4c6c-b8f8-e1aaadcac602/cd.js';
      cookiebotCookieDeclaratioScript.type = 'text/javascript';
      cookiebotCookieDeclaratioScript.async = true;

      const cookiebotWrapperEl = document.getElementById(COOKIEBOT_DECLARATION_WRAPPER_ID);
      if (cookiebotWrapperEl) {
        cookiebotWrapperEl.appendChild(cookiebotCookieDeclaratioScript);
      }
    }
  }, []);

  return <div id={COOKIEBOT_DECLARATION_WRAPPER_ID}></div>;
}
