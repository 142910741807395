import React from 'react';
import clsx from 'clsx';
import styles from '../cookies.module.css';
import Layout from 'components/layout';
import Footer from 'components/layout/footer';
import { meta } from 'metaData';
import CookieBot from '../../cookies';
import { sectionRender, contentListRender, talkEmailLink, homeLink } from '../sectionRender';

const reasonsList = [
  'To respond to the requests that you send through the "Contact Us"; form or through an interactive chat on the website',
  'To personalize and customize content and information that we send to our clients and partners, thus helping you quickly find software, services or information you require',
  'To monitor the trends, usage and multiple activities related to our services',
  'To build a fruitful mutual business collaboration between you and Brocoders',
  'To promote our products and services by sending you relevant marketing content: promotional materials, business news, books, invitations to our events and other information in case we acquired your permission',
  'To understand how our site works and how it is used in order to enhance its features, maintain its proper functionality and improve your user experience',
  'To ensure strict accordance with applicable legal requirements, relevant industry standards and this Privacy Policy',
  'To conduct various marketing analyses in order to understand our audiences and to improve our operations and services',
  'To optimize our recruiting strategies and use the data of job applicants in order to form a job offer',
  'To help us effectively troubleshoot any issues that you might experience with our products and services',
  'We can also utilize technologies known as "web beacons" and "embedded script" to acquire information about your interactions with the site in order to analyze what content on the website is more effective',
];

const collectData = [
  'Your first name and last name',
  'Your date of birth',
  'Your email address',
  'Your phone number',
  'Information about the company you work at',
  'The location of your company',
  'Your job title',
  'Information about your location at country and city level: state, province, ZIP/postal code, city',
  'Information on how you use our site, like whether you downloaded our marketing materials or filled out the "Contact Us" form, as well as information about visited web pages and their URL addresses',
  'Information about your device and browser: IP address, operating system version, device type, browser type, system and performance information',
  'Information about what website has referred you to us',
  'Your LinkedIn profile or other social networks addresses',
  'Other personal data that you share with us in the "Contact Us" form',
];

const sources = [
  'The "Contact Us" form or interactive chats located on the site',
  'Your Brocoders blog subscription profile',
  'We can collect information from email or open sources and put it into our CRM in case you contacted us directly by email or your social network profile',
  'We can also gather your personal data during business calls or meetings',
];

const informations = [
  'If required to do so by law',
  'To comply with legal proceedings',
  'In the event of an emergency, such as physical injury to a person',
  'To protect the rights, property or personal safety of Brocoders and its employees',
  'To protect the personal safety of users or the public',
];

const rights = [
  'The right to request information on how your personal data is collected, processed and utilized',
  'The right to request access to the data that we acquire',
  'The right to request the erasure of the information, which means removing all data related to a user',
  'The right to withdraw consent to the processing of marketing data at any time',
  'The right to object to our operations related to your personal information',
  'The right to restrict the processing of your personal data',
  'The right to data portability, which means the possibility to export your personal data to the digital format',
  'The right to rectification, which means you can command us to correct your personal information in case such information is inaccurate or outdated',
  'The right to appeal to the supervisory authority if you believe that the processing of your personal data violates the rules of the GDPR',
];

const security = [
  'All our employees are obliged not to process your information for the purposes and goals that differ from those described in this Policy',
  'We stick to the Information Security Policy, which applies to all our employees or other individuals who can acquire authorized access to information resources',
  'Our Information Security Policy covers all the data that we keep on physical or digital data carriers',
  'We review and update our security strategies and methodologies on an annual basis',
  'All privacy and security-related documents are approved by the top executive management',
  'We tend to run mandatory privacy and security training courses for all our employees and consultants',
  'We ensure that our internal authentication and authorization controls are robust and adequately treated',
  'We continuously gather and analyze the data regarding all the new  and existing threats and vulnerabilities, actual hacker attacks, as well as the information about the effectiveness of the existing security methodologies',
  'Our servers, workstations and internet gateway devices are updated periodically with the latest antivirus definitions that include zero-day anti-malware protection.',
  'We conduct a risk assessment on an annual basis',
];

const browsers = [
  {
    name: 'Internet Explorer',
    steps: [
      'Choose "Tools"',
      'Click on "Internet Options"',
      'Click the "Privacy" tab',
      'Move the slider to choose your settings',
    ],
    link: 'https://support.microsoft.com/en-us/hub/4338813/windows-help?os=windows-10',
  },
  {
    name: 'Safari',
    steps: [
      'Choose "Preferences" from Safari menu',
      'Select "Security"',
      'Cookie settings are shown',
      'Choose your settings',
    ],
    link: 'https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac',
  },
  {
    name: 'Google Chrome',
    steps: [
      'Click on the "Tools" menu',
      'Select "Options"',
      'Find the "Privacy and Security" section',
      'Click "Cookies and other site data."',
      'Click the "Cookie settings" tab and choose your settings',
    ],
    link: 'https://support.google.com/chrome/answer/95647?hl=en',
  },
  {
    name: 'Mozilla Firefox',
    steps: [
      'Click on "Menu", then "Options"',
      'Select "Privacy"',
      'Select "Cookies"',
      'Choose your settings',
    ],
    link: 'https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies',
  },
];

const brocodersWebPrivacyPolicyParagraphs = [
  {
    type: 'subtitle',
    content: 'Brocoders Web Privacy Policy',
    anchor: 'Brocoders Web Privacy Policy',
  },
  {
    type: 'paragraphs',
    content: [
      'Last updated: July 2020',
      'We at Brocoders pay close attention to protecting the personal data of our clients and undertake to ensure that their privacy rights are not violated in any way.',
      `We want you to know your rights and choices. On this page, Brocoders sets out how we collect, use, maintain and disclose your data, including information that you provide to us through our website ${homeLink} and its mobile version as well.`,
      'We have prepared this document in a format that is understandable and easy to read for all clients and users of our site. Hopefully, our Privacy Policy is not going to be the one you always skip through.',
      'Brocoders Services OU and its subsidiaries may further be referred to as "we", "company", "us", "Brocoders". Our custom- designed products, services and expertise — as "services".',
    ],
  },
];

const whoWeAreParagraphs = [
  {
    type: 'subtitle',
    content: 'Who we are',
    anchor: 'Who we are',
  },
  {
    type: 'paragraphs',
    content: [
      'Brocoders is a software development company that provides a wide range of tech-related services to startups and fast-growing businesses worldwide with its registered office address: Kentmanni tn 4 // Sakala tn 10, Tallinn, 10116, Estonia.',
      'This site is basically our marketing tool, and any information processed through the website is used to achieve particular marketing and business goals.',
    ],
  },
];

const whyDoWeCollectParagraphs = [
  {
    type: 'subtitle',
    content: 'Why do we collect information about you?',
    anchor: 'Why do we collect information about you?',
  },
  {
    type: 'paragraphs',
    content: [
      'We gather your personal data in full compliance with the GDPR, and other data privacy legislation acts and utilize it solely for the purposes of operating more efficiently and providing the best possible client-oriented services.',
      'We can analyze and use your personal data for various marketing purposes: to find out what products you are interested in or looking for at the moment, while improving our services by delivering extra features and content.',
      'Generally, we use the personal data we collect from you for the following reasons:',
    ],
  },
  {
    type: 'list',
    content: reasonsList,
  },
  {
    type: 'paragraphs',
    content: [
      'Please note: except for specific data required by law, your decision to provide any information to us is voluntary.',
      'We will never use your personal data to send you advertising and promotional messages from third parties. We will not use your personal data for the purposes incompatible with the goals of which you have been informed above unless it is required by law, or it is in your own vital interest (e.g. in case of a medical emergency) to do so.',
      `Please also keep in mind that you may withdraw your consent to receiving marketing materials and information from us at any time by leaving us a note at our email ${talkEmailLink} or by clicking the unsubscribe button under any email received from us.`,
      `If you have agreed to our terms for using your personal data for marketing and analytical goals, you may always object to our processing activities and request Brocoders to completely erase that data from our systems by contacting us at ${talkEmailLink}.`,
      'Please also note that if you withdraw your consent for direct marketing by clicking the unsubscribe button, we will not delete the rest of your data automatically. But you may request us to do so by sending us an email to the address above.',
    ],
  },
];

const whatKindOfInformation = [
  {
    type: 'subtitle',
    content: 'What kind of information do we gather?',
    anchor: 'What kind of information do we gather?',
  },
  {
    type: 'paragraphs',
    content: [
      'We collect and utilize only your basic personal data when you use our site. We can also gather and use some open-source information, such as addresses to social networking sites.',
      'You may be asked for, as appropriate, the following types of personal data:',
    ],
  },
  {
    type: 'list',
    content: collectData,
  },
];

const howDoWeCollect = [
  {
    type: 'subtitle',
    content: 'How do we collect your personal data?',
    anchor: 'How do we collect your personal data?',
  },
  {
    type: 'paragraphs',
    content: [
      'We can either gather information by ourselves (through contact forms) or utilize various tracking technologies (analytics providers like Google Analytics, Statcounter, Yandex Metrika, etc.), advertising networks (Google Adwords, Bing Ads, etc.) and search engines.',
      'Foremost, we collect your personal data from the following sources:',
    ],
  },
  {
    type: 'list',
    content: sources,
  },
];

const disclosureOfYourPersonalData = [
  {
    type: 'subtitle',
    content: 'Disclosure of your personal data',
  },
  {
    type: 'paragraphs',
    content: [
      'We do not sell or rent our users data to others. However, we may share your personal data with our trusted partners, as well as with service providers who perform services on our behalf in case you have given us your permission.',
      'Brocoders will only disclose personal information to perform the marketing goals mentioned above and will take all required steps to safeguard your personal data. We guarantee a high-assurance level of personal data protection by entering into stringent confidentiality agreements with the third parties.',
      'Please keep in mind that this website may be hosted outside your country, so when necessary, personal information may be transferred from one country to another, as wellas your personal information may be stored on a server hosted outside your current location. In such a case, we are committed to protecting the privacy of your personal data by taking all the necessary security procedures.',
    ],
  },
  {
    type: 'list',
    content: informations,
  },
];

const whereDoWeProcessYourData = [
  {
    type: 'subtitle',
    content: 'Where do we process your data?',
  },
  {
    type: 'paragraphs',
    content: [
      'The privacy of our EU-related users is protected by the General Data Protection Regulation (GDPR). Brocoders has brought its policies in full accordance with the GDPR regarding the gathering, analyzing and storage of personal information.',
      'In view of the above, your information can be processed in the European Union and can be transferred to the United States or Ukraine, but it will always be safely secured, despite the location of processing operations.',
    ],
  },
];

const dataRetentionTime = [
  {
    type: 'subtitle',
    content: 'Data retention time',

    anchor: 'Data retention time',
  },
  {
    type: 'paragraphs',
    content: [
      'We store your personal data for as long as we need it to perform marketing analyzes or as long as we run a mutual business collaboration with you. We will delete or anonymize such data when it is no longer needed, or there is no legal basis to process it.',
      "In case we didn't start any kind of collaboration, we will keep your data for some limited period only to conduct marketing research, as well as to contact your company later as our potential partner.",
      'Please also note that you can always request us to completely delete your personal data as described in the "Why do we collect information about you" section.',
    ],
  },
];

const yourUserRights = [
  {
    type: 'subtitle',
    content: 'Your user rights',
    anchor: 'Your user rights',
  },
  {
    type: 'paragraphs',
    content: [
      'All users are treated equally in compliance with the current Privacy Policy and existing legal acts, and have the following rights regarding data that we gather:',
    ],
  },
  { type: 'list', content: rights },
];

const yourCaliforniaPrivacyRights = [
  {
    type: 'subtitle',
    content: `Your California Privacy Rights (California's Shine the Light law)`,
    anchor: `Your California Privacy Rights (California's Shine the Light law)`,
  },
  {
    type: 'paragraphs',
    content: [
      `This section applies only to California consumers and describes how we gather, utilize and share California consumers Personal Information. Under California Civil Code Section 1798 (California's Shine the Light law), if you are a California resident who has established business collaboration with Brocoders, you can request information once a year about the data we share with other organizations for their marketing purposes. Please contact us at ${talkEmailLink}.`,
    ],
  },
];

const californiaPrivacyRights = [
  {
    type: 'subtitle',
    content: `California Privacy Rights for Minor Users (California Business and Professions Code
      Section 22581)`,
    anchor: `California Privacy Rights for Minor Users (California Business and Professions Code
      Section 22581)`,
  },
  {
    type: 'paragraphs',
    content: [
      'California Business and Professions Code section 22581 allows California residents under the age of 18 who are registered users of online sites, services or applications to request and obtain removal of content or information they have publicly posted.',
      `To erase such information, you can contact us at ${talkEmailLink} and include the email address associated with your account.`,
      'Be aware that your request does not guarantee the complete or comprehensive removal of content or information posted online and that the law may not permit or require removal under certain circumstances.',
    ],
  },
];

const childrenPrivacy = [
  {
    type: 'subtitle',
    content: "Children's Privacy",
    anchor: "Children's Privacy",
  },
  {
    type: 'paragraphs',
    content: [
      "Brocoders don't gather information from individuals under the age of 16. We request that you must be legally adult to use the Brocoders site or you shall do so only with the involvement, supervision and approval of your parents or a legal guardian.",
      `If you managed to find out that a child has provided us with information, please contact us at ${talkEmailLink}. Once we become aware that someone under the age of 16 has submitted his data, we will delete such information from our records immediately.`,
    ],
  },
];

const dataSecurity = [
  {
    type: 'subtitle',
    content: 'Data security',
    anchor: 'Data security',
  },
  {
    type: 'paragraphs',
    content: [
      'We empowered our website with robust security measures to safeguard personal information from unauthorized access, theft, misuse, alteration or disclosure. Most of the Brocoders employees have restricted access to your data, so the data is available only for those experts related to customer management.',
      'Even though we apply all the best possible security precautions, you should understand that no website or data transfer is invulnerable and completely secure. Given this fact, we cannot guarantee a 100% protection of your data, as well as we cannot guarantee that data breaches will never occur. You provide us with your personal data at your own risk.',
    ],
  },
  {
    type: 'list',
    content: security,
  },
];

const cookiesPolicy = [
  {
    type: 'subtitle',
    content: 'Cookies policy',
    anchor: 'Cookies policy',
  },
  {
    type: 'paragraphs',
    content: [
      `By pressing the pop-up "Accept" button, you provide your consent for our use of cookies
      and agree to our Cookie policy. We use cookies and other tracking technologies on our
      website to allow for smoother user experience and site navigation. Cookies enable us to
      analyze trends, administer our site, monitor your activity and traffic patterns, as well
      as track the pages that you find most helpful.`,
      `Cookies are small chunks of data sent from a website and stored on your computer,
      smartphone, tablet or other devices. Most web browsers accept cookies by default, but
      this option can be disabled via your browser's settings. Cookies can be "persistent" or
      "session". Persistent Cookies remain on your personal computer or mobile device when you
      go offline, while Session Cookies are deleted as soon as you close your web browser.`,
    ],
  },
];

const howToOutOfCookies = [
  {
    type: 'subtitle',
    content: 'How to opt-out of cookies',
    anchor: 'How to opt-out of cookies',
  },
  {
    type: 'paragraphs',
    content: [
      `By pressing the pop-up "Accept" button, you provide your consent for our use of cookies
      and agree to our Cookie policy. We use cookies and other tracking technologies on our
      website to allow for smoother user experience and site navigation. Cookies enable us to
      analyze trends, administer our site, monitor your activity and traffic patterns, as well
      as track the pages that you find most helpful.`,
      `Cookies are small chunks of data sent from a website and stored on your computer,
      smartphone, tablet or other devices. Most web browsers accept cookies by default, but
      this option can be disabled via your browser's settings. Cookies can be "persistent" or
      "session". Persistent Cookies remain on your personal computer or mobile device when you
      go offline, while Session Cookies are deleted as soon as you close your web browser.`,
    ],
  },
  {
    type: 'custom',
    content: () => (
      <>
        {browsers.map((item, idx) => (
          <div key={idx}>
            <div>
              Opting out of cookies in <i>{item.name}</i>
            </div>
            <ol className={styles.numericList}>
              {item.steps.map((step, index) => (
                <li key={index}>
                  <div className={styles.listItemWrapper}>{step}</div>
                </li>
              ))}
            </ol>
            <p className={styles.paragraph}>
              More information on{' '}
              <a target="_blank" className={clsx(styles.link, styles.inline)} href={item.link}>
                Support page
              </a>
            </p>
          </div>
        ))}
        <p className={styles.paragraph}>
          To learn more about cookies, you can visit{' '}
          <a
            target="_blank"
            className={clsx(styles.link, styles.inline)}
            href="https://www.termsfeed.com/blog/cookies/"
          >
            this page
          </a>
          .
        </p>
      </>
    ),
  },
];

const amendmentsPrivacyPolicy = [
  {
    type: 'subtitle',
    content: 'Amendments to this Privacy Policy',
    anchor: 'Amendments to this Privacy Policy',
  },
  {
    type: 'paragraphs',
    content: [
      `Brocoders reserves the right to change, edit and update this Privacy Policy from time to
      time. When we do, we will revise the updated day and will notify you or seek your
      consent. We recommend you to periodically check this page for any possible updates to
      stay informed about how we are enhancing our data privacy approaches.`,
    ],
  },
];

const yourAcceptance = [
  {
    type: 'subtitle',
    content: 'Your acceptance of these terms',
    anchor: 'Your acceptance of these terms',
  },
  {
    type: 'paragraphs',
    content: [
      `By using this site and sharing your contact details with Brocoders Services OU, you signify your
      acceptance of this Policy. The site is available only to individuals who are at least 16
      years old. By using this website, you agree that you're at least 16 years old.`,
      'If you do not agree to this Policy, please do not use our website.',
    ],
  },
];

const contactInformation = [
  {
    type: 'subtitle',
    content: 'Contact information',
    anchor: 'Contact information',
  },
  {
    type: 'paragraphs',
    content: [
      `Do you have any questions left about this Privacy Policy? We are eager to answer your questions and provide you with more info on how we handle your data. Please don't hesitate to drop us a line at ${talkEmailLink}.`,
    ],
  },
];

const pageSections = [
  brocodersWebPrivacyPolicyParagraphs,
  whoWeAreParagraphs,
  whyDoWeCollectParagraphs,
  whatKindOfInformation,
  howDoWeCollect,
  disclosureOfYourPersonalData,
  dataRetentionTime,
  yourUserRights,
  yourCaliforniaPrivacyRights,
  californiaPrivacyRights,
  childrenPrivacy,
  dataSecurity,
  cookiesPolicy,
  howToOutOfCookies,
  amendmentsPrivacyPolicy,
  yourAcceptance,
  contactInformation,
];

function PrivacyPolicy() {
  return (
    <Layout isRed metaData={{ main: meta.cookies }}>
      <main className={clsx(styles.main, 'inner-container')}>
        <h1 className={styles.title}>Cookie Policy</h1>

        <ul className={styles.list}>
          {pageSections.map((item) => contentListRender(item))}
          <a
            href="#cookies-declaration"
            className={clsx(styles.link, styles.contentLinks)}
            key="cookies-declaration"
          >
            Cookies declaration
          </a>
        </ul>
        {pageSections.map((item) => sectionRender(item))}
        <h2 className={styles.subtitle} id="cookies-declaration">
          Cookies declaration
        </h2>
        <CookieBot />
      </main>
      <Footer />
    </Layout>
  );
}

export default PrivacyPolicy;
