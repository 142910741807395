import React from 'react';
import clsx from 'clsx';
import styles from './cookies.module.css';
import { v4 as uuidv4 } from 'uuid';

export const homeLink = '$homeLink$';
export const talkEmailLink = '$talkEmailLink$';
export const boldText = '$boldText$';

const brocodersMainLinkElement = (
  <a href="https://brocoders.com/" className={clsx(styles.link, styles.inline)} target="_blank">
    https://brocoders.com/
  </a>
);

const talkEmailLinkElement = (
  <a target="_blank" href="mailto:talk@brocoders.team" className={clsx(styles.link, styles.inline)}>
    talk@brocoders.team
  </a>
);

interface ISection {
  type: string;
  content: string[] | string | Function | [string, string][];
  anchor?: string;
}

export const sectionRender = (content: Array<ISection>) =>
  content.map(({ type, content, anchor }) => {
    switch (type) {
      case 'subtitle': {
        return anchor ? (
          <h2 key={uuidv4()} className={styles.subtitle} id={anchor}>
            {content}
          </h2>
        ) : (
          <h2 key={uuidv4()} className={styles.subtitle}>
            {content}
          </h2>
        );
      }
      case 'subtitleH3': {
        return anchor ? (
          <h3 key={uuidv4()} className={styles.subtitleH3} id={anchor}>
            {content}
          </h3>
        ) : (
          <h3 key={uuidv4()} className={styles.subtitleH3}>
            {content}
          </h3>
        );
      }
      case 'subtitleH4': {
        return anchor ? (
          <h4 key={uuidv4()} className={styles.subtitleH4} id={anchor}>
            {content}
          </h4>
        ) : (
          <h4 key={uuidv4()} className={styles.subtitleH4}>
            {content}
          </h4>
        );
      }
      case 'paragraphs': {
        if (Array.isArray(content))
          return content.map((item) => {
            if (item.includes(homeLink) && typeof item === 'string') {
              const splitItem = item.split(homeLink);
              return (
                <p className={styles.paragraph} key={uuidv4()}>
                  {splitItem[0]}
                  {brocodersMainLinkElement}
                  {splitItem[1]}
                </p>
              );
            }
            if (item.includes(talkEmailLink) && typeof item === 'string') {
              const splitItem = item.split(talkEmailLink);
              return (
                <p className={styles.paragraph} key={uuidv4()}>
                  {splitItem[0]}
                  {talkEmailLinkElement}
                  {splitItem[1]}
                </p>
              );
            }
            if (item.includes(boldText) && typeof item === 'string') {
              const splitItem = item.split(boldText);
              return (
                <p className={styles.paragraph} key={uuidv4()}>
                  {splitItem[0]}
                  <b>{splitItem[1]}</b>
                  {splitItem[2]}
                </p>
              );
            } else {
              return (
                <p className={styles.paragraph} key={uuidv4()}>
                  {item}
                </p>
              );
            }
          });
      }
      case 'list': {
        return (
          <ul className={styles.list}>
            {Array.isArray(content) &&
              content.map((item) => (
                <li className={clsx(styles.paragraph, styles.listItem)} key={uuidv4()}>
                  {item}
                </li>
              ))}
          </ul>
        );
      }
      case 'highlighted list': {
        return (
          <ul className={styles.list}>
            {Array.isArray(content) &&
              content.map((item) => (
                <li className={clsx(styles.paragraph, styles.listItem)} key={uuidv4()}>
                  <b>{item[0]} </b>
                  {item[1]}
                </li>
              ))}
          </ul>
        );
      }
      case 'custom': {
        return content();
      }
    }
  });
export const contentListRender = (content: Array<ISection>) => {
  return content.map(
    ({ anchor }) =>
      anchor && (
        <a key={anchor} href={`#${anchor}`} className={clsx(styles.link, styles.contentLinks)}>
          {anchor}
        </a>
      )
  );
};
